import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Section from '../components/section';

export default function Policy({ data }) {
  const pageInfo = {
    id: 'policy-page',
    url: '/policy',
    title: 'Политика',
    description: 'Политика КМК клиники.',
    breadcrumbs: [
      {
        title: 'Главная',
        link: '/',
      },
      {
        title: 'Политика',
        link: null,
      },
    ],
  };

  return (
    <Layout pageInfo={pageInfo}>
      <Section>
        <article>
          <ReactMarkdown>
            {data.strapiGeneral.policy}
          </ReactMarkdown>
        </article>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query {
    strapiGeneral {
      name
      policy
    }
  }
`;
